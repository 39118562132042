import {
  addGroupNumbers,
  animationState,
  createGrid,
  drawCells,
  setDimensions,
  updateAllCellsState
} from '../artwork/artwork'
import { awaitNewAnimationFrame } from '../artwork/lib/anim'
import { viewport } from '../artwork/lib/viewport'
import { Cell, CellState, Rule } from '../artwork/types'
import { useEffect, useRef, useState } from 'react'
import Stats from 'stats.js.fps'
import { resetPalette } from '../artwork/palette'

const stats = new Stats()
if (window.location.hash === '#debug') {
  stats.showPanel(0)
  document.body.appendChild(stats.dom)
}

interface Props {
  rules: Rule[]
}

let grid: Cell[] = []
let maxUpdateIndex = 0
let canvas: HTMLCanvasElement

window.addEventListener('resize', () => {
  window.location.href = window.location.href
})

export default function Artwork({ rules }: Props) {
  const container = useRef<HTMLDivElement>(null)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    resetPalette()
    grid = createGrid(rules, (Math.random() * 0xffffff) << 0)
    grid = addGroupNumbers(grid)
    maxUpdateIndex = 0
  }, [rules])

  function setCanvasDimensions() {
    const vp = viewport()
    canvas.width = vp[0] * window.devicePixelRatio
    canvas.height = vp[1] * window.devicePixelRatio
    canvas.style.width = `${vp[0]}px`
    canvas.style.height = `${vp[1]}px`
    canvas.style.position = 'fixed'
    canvas.style.left = '0px'
    canvas.style.top = '0px'
  }

  useEffect(() => {
    if (!container.current || initialized || !grid) return
    setInitialized(true)

    canvas = document.createElement('canvas')
    setCanvasDimensions()
    const ctx = canvas.getContext('2d')!

    const cellsState: CellState[] = []
    container.current.appendChild(canvas)
    setDimensions()
    for (let i = 0; i < grid.length; i++) {
      cellsState[i] = {
        length: 1,
        angle: 0,
        color: [0, 0, 0],
        completed: false
      }
    }

    function draw() {
      ctx.fillStyle = '#7f7f7f'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      updateAllCellsState(grid!, cellsState, maxUpdateIndex)
      if (maxUpdateIndex < cellsState.length) {
        maxUpdateIndex += 4 / speedRatio()
      }
      
      drawCells(ctx, cellsState)
    }

    async function theLoop(): Promise<void> {
      let introCompleted = false
      setTimeout(() => {
        introCompleted = true
      }, 2000)
      while (true) {
        stats.begin()
        if (introCompleted) draw()
        await awaitNewAnimationFrame()
        stats.end()
      }
    }

    theLoop()
  }, [container, initialized])

  return (
    <div
      ref={container}
      style={{
        boxSizing: 'border-box',
        background: 'white',
        padding: '10px'
      }}
    />
  )
}

const DEFAULT_FPS = 30

let measurements = 0
let sum = 0
export function fps(): number {
  if (measurements < 500) {
    sum += stats.fps
    measurements += 1
  }
  return sum / measurements 
}

export function speedRatio(): number {
  return fps() / DEFAULT_FPS
}
