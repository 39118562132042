import { CellKind, Pattern, Rule, StepChances } from '../types'
import { all, maybe, random, sample } from './random'

export function getRandomRules(): Rule[] {
  let seed = (0xfffffff * Math.random()) << 0

  const result: Rule[] = [
    {
      pattern: [null, null, null, null],
      chances: randomRuleChances(seed * 0x3213)
    }
  ]

  const extraRuleCount = random(seed, 3)
  for (let i = 0; i < extraRuleCount; i++) {
    const extraRule: Rule = {
      pattern: randomRulePattern(seed * 0x221 + i),
      chances: randomRuleChances(seed * 0x321 + i)
    }
    result.push(extraRule)
  }
  return result
}

export function mutateRules(oldRules: Rule[]): Rule[] {
  return oldRules
}

function randomRulePattern(seed: number): Pattern {
  const result: Pattern = [null, null, null, null]
  for (let i = 0; i < 4; i++) {
    result[i] = maybe(seed * 0x34 + i, 0.25)
      ? ((random(seed * i, 3) + 1) as CellKind)
      : null
  }
  return result
}

function randomRuleChances(seed: number): StepChances {
  const result: StepChances = [0, 0, 0, 0, 0, 0]
  const values = [0, 1, 10, 100] // , 3, 5, 10]
  for (let i = 1; i <= 4; i++) {
    result[i] = sample(seed * 0x310 + i, values)
  }
  if (all(result, 0)) {
    const index = random(seed, 2) + 1
    result[index] = 1
  }
  return result
}
