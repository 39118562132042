import { random, sample } from './lib/math'
import { shuffle } from './lib/random'
import { RGB } from './types'

export const _palette: RGB[] = [
  // [0, 200, 0],
  [255, 0, 0],
  [0, 0, 255],
  [255, 255, 255],
  // [0, 0, 128],
  // [252, 2, 160],
  [0, 0, 0],
  [255, 255, 0]
  // [252, 172, 1],
  // [0, 216, 254]
]

// export const palette: RGB[] = [
//   [228, 127, 61],
//   [80, 130, 65],
//   [124, 45, 29],
//   [224, 79, 58],
//   [27, 70, 168],
//   [130, 174, 249],
//   [168, 54, 47],
//   [235, 74, 104],
//   [0, 0, 0]
// ]

const fullPalette: RGB[] = [
  [0, 0, 0],
  [255, 255, 255],
  [192, 232, 162],
  [255, 0, 0],
  [255, 255, 0],
  [255, 0, 255]
]

let palette = samplePalette(fullPalette)

export function getCurrentPalette(): RGB[] {
  return palette
}

function createRandomOrderPalette(input: RGB[]): RGB[] {
  const result: RGB[] = []
  for (let i = 0; i < 10; i++) {
    result.push(...shuffle(input))
  }
  return result
}

export function samplePalette(input: RGB[]): RGB[] {
  const result: RGB[] = []
  const size = random(input.length) + 2
  console.log(size, 'colours')
  for (let i = 0; i < size; i++) {
    result.push(sample(input) as RGB)
  }
  return result
}

export function resetPalette(): void {
  palette = samplePalette(fullPalette)
}
