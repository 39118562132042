// 0 = _
// 1 = ◢
// 2 = ◣
// 3 = ◥
// 4 = ◤
// 5 = []

import { speedRatio } from '../components/Artwork'
import { RGBToString } from './lib/colours'
import { mul2 } from './lib/math'
import { random } from './lib/random'
import { viewport } from './lib/viewport'
import { getCurrentPalette } from './palette'
// import { palette as fullPalette, samplePalette } from './palette'
import { getMatchingRule } from './rules'
import {
  Cell,
  CellKind,
  CellState,
  Neighbours,
  RGB,
  Rule,
  StepChances
} from './types'

const HORIZONTAL_MARGIN_PERCENTAGE = 2.5
const COLOUR_EASING = 165
const TRANSITION_SPEED_DIV = 50

// export let currentPalette = samplePalette(fullPalette)

interface State {
  cols: number
  rows: number
  verticalMargin: number
  horizontalMargin: number
  cellSize: number
}

export let animationState: State | undefined

const COLUMNS = 44 //window.location.hash ? parseInt(window.location.hash.slice(1)) : 32

export function setDimensions(): void {
  const vp = mul2(viewport(), window.devicePixelRatio)
  const horizontalMargin = (vp[0] * HORIZONTAL_MARGIN_PERCENTAGE) / 100 // 5% margin

  const horizontalSpaceWithinMargins = vp[0] - horizontalMargin * 2
  const verticalSpaceWithinMargins = vp[1] - horizontalMargin * 2
  const withMarginsRatio =
    horizontalSpaceWithinMargins / verticalSpaceWithinMargins
  const cols = withMarginsRatio > 1.2 ? COLUMNS : withMarginsRatio < 0.7 ? 24 : 24
  const rows = Math.round(cols / withMarginsRatio)
  const cellSize = (vp[0] - horizontalMargin * 2) / cols

  const heightTakenUpByCells = rows * cellSize
  const verticalMargin = (vp[1] - heightTakenUpByCells) / 2
  animationState = {
    verticalMargin,
    rows,
    horizontalMargin,
    cellSize,
    cols
  }
}

function cellAt(grid: Cell[], x: number, y: number): Cell {
  const cell = grid[x + y * animationState!.cols]
  if (
    cell === undefined ||
    y < 0 ||
    x < 0 ||
    x >= animationState!.cols ||
    y >= animationState!.rows
  )
    return { kind: 0, group: null }
  return cell
}

function sampleNextStep(array: StepChances, seed: number): CellKind {
  const options: number[] = []
  array.forEach((element, index) => {
    for (let i = 0; i < element * 10; i++) {
      options.push(index)
    }
  })
  const randomIndex = Math.round(random(seed, options.length - 1))
  return options[randomIndex] as CellKind
}

export function getNextCellKind(
  neighbours: Neighbours,
  seed: number,
  rules: Rule[]
): CellKind {
  const matchingRule = getMatchingRule(neighbours, rules)
  return sampleNextStep(matchingRule.chances, seed)
}

function getNextCell(
  grid: Cell[],
  rules: Rule[],
  x: number,
  y: number,
  seed: number
): Cell {
  const stepLeft = cellAt(grid, x - 1, y)
  const step2Left = cellAt(grid, x - 2, y)
  const stepAbove = cellAt(grid, x, y - 1)
  const stepAboveRight = cellAt(grid, x + 1, y - 1)
  const neighbours: Neighbours = [
    stepLeft.kind,
    stepAbove.kind,
    stepAboveRight.kind,
    step2Left.kind
  ]
  const selectFrom = getNextCellKind(neighbours, seed, rules)
  if (selectFrom === undefined) {
    throw new Error('cannot find next possible cell step')
  }

  return {
    kind: selectFrom,
    group: null
  }
}

export function createGrid(rules: Rule[], seed: number): Cell[] {
  setDimensions()
  const result: Cell[] = []
  let newSeed = seed
  for (let y = 0; y < animationState!.rows; y++) {
    for (let x = 0; x < animationState!.cols; x++) {
      newSeed++
      let nextCell = getNextCell(result, rules, x, y, newSeed)
      result[x + y * animationState!.cols] = nextCell
    }
  }
  return result
}

function getTargetAngle(kind: CellKind): number {
  switch (kind) {
    case 0:
      return 0
    case 1:
      return (45 / 360) * Math.PI * 2
    case 2:
      return (90 / 360) * Math.PI * 2
    case 3:
      return (-45 / 360) * Math.PI * 2
    case 4:
      return 0
    default:
      throw new Error('unknown cellkind, cannot determine getTargetAngle')
  }
}

function getTargetLengthMultiplier(kind: CellKind): number {
  if (kind === 0 || kind === 2 || kind === 4) return 1.85
  // if ([0, 2, 4].includes(kind)) return 1.85
  return 1.22
}

export function updateAllCellsState(
  grid: Cell[],
  cellState: CellState[],
  maxIndex: number
): void {

  const lastIndex = Math.min(maxIndex, cellState.length)
  for (let i = 0; i < lastIndex; i++) {
    // const cell = grid[i]
    updateCellState(grid[i], cellState[i])
  }
}

function updateCellStateColour(cell: Cell, cellState: CellState) {
  const targetColour = getCellColour(cell.group)
  const currentColour: RGB = cellState.color
  const factor = COLOUR_EASING * speedRatio()
  currentColour[0] +=
    (targetColour[0] - currentColour[0]) / factor
  currentColour[1] +=
    (targetColour[1] - currentColour[1]) / factor
  currentColour[2] +=
    (targetColour[2] - currentColour[2]) / factor
}

function updateCellState(cell: Cell, cellState: CellState): void {
  const targetAngle = getTargetAngle(cell.kind)
  const directionAngle = targetAngle > cellState.angle ? 1 : -1

  if (Math.abs(targetAngle - cellState.angle) > 0.02) {
    cellState.angle += directionAngle / TRANSITION_SPEED_DIV / speedRatio()
  } else {
    if (cellState.angle !== targetAngle) {
      cellState.angle = targetAngle
    }
    updateCellStateColour(cell, cellState)
  }

  const targetLength = getTargetLengthMultiplier(cell.kind)
  const directionLength =
    targetLength > cellState.length ? 1 : -1
  if (
    Math.abs(targetLength - cellState.length) > 0.01
  ) {
    cellState.length += directionLength / TRANSITION_SPEED_DIV / speedRatio()
  } else {
    if (cellState.length !== targetLength) {
      cellState.length = targetLength
    }
  }
}

export function drawCells(
  ctx: CanvasRenderingContext2D,
  cellState: CellState[]
): void {
  ctx.lineWidth = animationState!.cellSize / 4.2
  for (let i = 0; i < cellState.length; i++) {
    const x = i % animationState!.cols
    const y = (i / animationState!.cols) << 0
    drawCell(ctx, x, y, cellState[i])
  }
}

function drawCell(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  cellState: CellState
): void {
  const { cellSize, horizontalMargin, verticalMargin } = animationState!
  const r = cellSize / cellState.length
  const cellSizeHalf = cellSize / 2
  const c1 = x * cellSize + cellSizeHalf + horizontalMargin
  const c2 = y * cellSize + cellSizeHalf + verticalMargin
  const p1 = Math.cos(cellState.angle) * r
  const p2 = Math.sin(cellState.angle) * r

  ctx.strokeStyle = RGBToString(cellState.color)
  
  ctx.beginPath()
  ctx.moveTo(c1 - p1, c2 - p2)
  ctx.lineTo(c1 + p1, c2 + p2)
  ctx.stroke()
}

function getCellColour(cellGroup: number | null): RGB {
  if (cellGroup === null) return [200, 200, 200]
  const currentPalette = getCurrentPalette()
  return currentPalette[cellGroup % currentPalette.length]
}

export function addGroupNumbers(grid: Cell[]): Cell[] {
  for (let i = 0; i < grid.length; i++) {
    const x = i % animationState!.cols
    const y = (i / animationState!.cols) << 0
    traverseCellToGiveGroup(grid, x, y, i)
  }
  return grid
}

function traverseCellToGiveGroup(
  grid: Cell[],
  x: number,
  y: number,
  newGroupNumber: number
): void {
  const cell = cellAt(grid, x, y)
  if (
    x < 0 ||
    x > animationState!.cols ||
    y < 0 ||
    y > animationState!.rows ||
    cell.group !== null
  ) {
    return
  }

  cell.group = newGroupNumber

  switch (cell.kind) {
    case 2: {
      if (cellAt(grid, x, y + 1).kind === 2) {
        traverseCellToGiveGroup(grid, x, y + 1, newGroupNumber)
      }
      break
    }
    case 4: {
      if (cellAt(grid, x + 1, y).kind === 4) {
        traverseCellToGiveGroup(grid, x + 1, y, newGroupNumber)
      }
      break
    }

    case 1: {
      //  \

      if (cellAt(grid, x - 1, y - 1).kind === 1) {
        // traverse above left is also \
        traverseCellToGiveGroup(grid, x - 1, y - 1, newGroupNumber)
      }
      if (cellAt(grid, x + 1, y + 1).kind === 1) {
        // traverse below right is also \
        traverseCellToGiveGroup(grid, x + 1, y + 1, newGroupNumber)
      }
      if (cellAt(grid, x, y + 1).kind === 3) {
        // traverse below is  /
        traverseCellToGiveGroup(grid, x, y + 1, newGroupNumber)
      }
      if (cellAt(grid, x, y - 1).kind === 3) {
        // traverse above is  /
        traverseCellToGiveGroup(grid, x, y - 1, newGroupNumber)
      }
      if (cellAt(grid, x - 1, y).kind === 3) {
        // traverse left is  /
        traverseCellToGiveGroup(grid, x - 1, y, newGroupNumber)
      }
      if (cellAt(grid, x + 1, y).kind === 3) {
        // traverse right is  /
        traverseCellToGiveGroup(grid, x + 1, y, newGroupNumber)
      }

      break
    }

    case 3: {
      // /
      if (cellAt(grid, x + 1, y).kind === 1) {
        // traverse when right is \
        traverseCellToGiveGroup(grid, x + 1, y, newGroupNumber)
      }
      if (cellAt(grid, x - 1, y).kind === 1) {
        // traverse when left is \
        traverseCellToGiveGroup(grid, x - 1, y, newGroupNumber)
      }
      if (cellAt(grid, x + 1, y - 1).kind === 3) {
        // traverse when above right is /
        traverseCellToGiveGroup(grid, x + 1, y - 1, newGroupNumber)
      }
      if (cellAt(grid, x - 1, y + 1).kind === 3) {
        // traverse when below left is /
        traverseCellToGiveGroup(grid, x - 1, y + 1, newGroupNumber)
      }
      if (cellAt(grid, x, y - 1).kind === 1) {
        // traverse when above is \
        traverseCellToGiveGroup(grid, x, y - 1, newGroupNumber)
      }
      if (cellAt(grid, x, y + 1).kind === 1) {
        // traverse when below is \
        traverseCellToGiveGroup(grid, x, y + 1, newGroupNumber)
      }
      break
    }
  }
}
